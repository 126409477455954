// 1. React and Hooks
import React from 'react';

// 2. Third-Party Libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// 3. Local Components

// 4. Assets (Images, etc.)
import BackgroundDark from '../../assets/hero/background_dark.png';
import BackgroundLight from '../../assets/hero/background_light.png';
import BackgroundDarkPortrait from '../../assets/hero/background_dark_portrait.png';
import BackgroundLightPortrait from '../../assets/hero/background_light_portrait.png';

import Billboard from '../../assets/hero/billboard.png';
import BillboardPortrait from '../../assets/hero/billboard_portrait.png';

import Lights from '../../assets/hero/lights.png';
import LightsPortrait from '../../assets/hero/lights_portrait.png';

// 5. Styles
import './Hero.css';

const Hero = ({ theme }) => {
  const isDarkMode = theme === 'dark';
  const { t } = useTranslation();

  return (
    <section className={`hero ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="background">
        <img 
          src={isDarkMode ? BackgroundDark : BackgroundLight} 
          alt={t('hero-background-alt')} 
          className="background-wide" 
        />
        <img 
          src={isDarkMode ? BackgroundDarkPortrait : BackgroundLightPortrait} 
          alt={t('hero-background-portrait-alt')} 
          className="background-portrait" 
        />
      </div>

      <div className="billboard">
        <img 
          src={Billboard} 
          alt={t('hero-billboard-alt')} 
          className="billboard-wide" 
        />
        <img 
          src={BillboardPortrait} 
          alt={t('hero-billboard-portrait-alt')} 
          className="billboard-portrait" 
        />
      </div>
      
      <div className="hero-content">
        <h1>{t('hero-title-line-1')}</h1>
        <h1>{t('hero-title-line-2')}</h1>
        <Link to="/ponuda" className="action-button">
          {t('hero-button')}
        </Link>
        <h3>{t('hero-subtext')}</h3>
      </div>

      {isDarkMode && (
        <div className="lights">
          <img 
            src={Lights} 
            alt={t('hero-lights-alt')} 
            className="lights-wide" 
          />
          <img 
            src={LightsPortrait} 
            alt={t('hero-lights-portrait-alt')} 
            className="lights-portrait" 
          />
        </div>
      )}
    </section>
  );
};

export default Hero;
