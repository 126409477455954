// 1. React and Hooks
import React, { useEffect } from 'react';

// 2. Third-Party Libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaHandshakeSimple, FaHeart } from 'react-icons/fa6';
import { MdBusinessCenter } from 'react-icons/md';

// 3. Local Components
import PageHeader from '../../components/PageHeader/PageHeader';

// 4. Assets (Images, etc.)
import OurStory from '../../assets/images/our_story.jpg';

// 5. Styles
import './AboutPage.css';

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("about-us-document-title");
  }, []);
  
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t("about-us-page-title")}
        subtitle={t("about-us-page-subtitle")}
      />
      <section className="about-us container" aria-label={t("about-us-section-aria")}>

          <article className="our-story" aria-labelledby="our-story-heading">
            <div className="story-image">
              <img src={OurStory} alt={t("about-us-our-story-alt")} />
            </div>
            <div className="story-text">
              <h1 id="our-story-heading">{t("about-us-our-story-title")}</h1>
              <p>{t("about-us-our-story-text-1")}</p>
              <p>{t("about-us-our-story-text-2")}</p>
              <p>{t("about-us-our-story-text-3")}</p>
              <p>{t("about-us-our-story-text-4")}</p>
            </div>
          </article>

          <section className="our-vision" aria-labelledby="our-vision-heading">
            <h1 id="our-vision-heading">{t("about-us-our-vision-title")}</h1>
            <p>{t("about-us-our-vision-text")}</p>

            <div className="vision-item empathy" aria-labelledby="empathy-heading">
              <div className="icon-container"><FaHeart /></div>
              <div className="vision-text">
                <h3 id="empathy-heading">{t("about-us-empathy-title")}</h3>
                <p>{t("about-us-empathy-text")}</p>
              </div>
            </div>
            <div className="vision-item professionalism" aria-labelledby="professionalism-heading">
              <div className="icon-container"><MdBusinessCenter /></div>
              <div className="vision-text">
                <h3 id="professionalism-heading">{t("about-us-professionalism-title")}</h3>
                <p>{t("about-us-professionalism-text")}</p>
              </div>
            </div>
            <div className="vision-item trust" aria-labelledby="trust-heading">
              <div className="icon-container"><FaHandshakeSimple /></div>
              <div className="vision-text">
                <h3 id="trust-heading">{t("about-us-trust-title")}</h3>
                <p>{t("about-us-trust-text")}</p>
              </div>
            </div>
          </section>

          <section className="our-services" aria-labelledby="services-heading">
            <h1 id="services-heading">{t("about-us-our-services-title")}</h1>
            <div className="services-container">
              <article className="service-card" aria-labelledby="service-1-title">
                <h3 id="service-1-title">{t("about-us-service-1-title")}</h3>
                <p>{t("about-us-service-1-text")}</p>
              </article>
              <article className="service-card" aria-labelledby="service-2-title">
                <h3 id="service-2-title">{t("about-us-service-2-title")}</h3>
                <p>{t("about-us-service-2-text")}</p>
              </article>
              <article className="service-card" aria-labelledby="service-3-title">
                <h3 id="service-3-title">{t("about-us-service-3-title")}</h3>
                <p>{t("about-us-service-3-text")}</p>
              </article>
            </div>
          </section>

          <section className="why-us" aria-labelledby="why-us-heading">
            <h1 id="why-us-heading">{t("about-us-why-us-title")}</h1>
            <p className="main-reason">{t("about-us-why-us-main-reason")}</p>
            <p className="sub-reason">{t("about-us-why-us-sub-reason")}</p>
            <Link to="/kontakt" className="action-button">{t("about-us-contact-us")}</Link>
          </section>

      </section>
    </>
  );
};

export default AboutPage;
