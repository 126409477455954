import React from 'react';

import './PageHeader.css';

const PageHeader = ({ title, subtitle }) => {
  return (
    <div className='container darker-background'>
      <header className='page-header'>
        <h1 className='page-title'>{title}</h1>
        {subtitle && <h3 className='page-subtitle'>{subtitle}</h3>}
      </header>
    </div>
  );
};

export default PageHeader;
