// 1. React and Hooks
import React, { useEffect, useState } from 'react';

// 2. Third-Party Libraries
import { FaPhoneAlt, FaEnvelope, FaFacebookF } from "react-icons/fa";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

// 3. Local Components
import PageHeader from '../../components/PageHeader/PageHeader';
import Logo from '../../components/Logo/Logo';

// 4. Assets (Images, etc.)

// 5. Styles
import './ContactPage.css';

const ContactPage = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("contact-document-title");
  }, []);
  
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL
  const API_KEY = process.env.REACT_APP_API_KEY

  // State to manage success or error messages
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState(null);

  // Function to send the form data to the backend
  const generateEmail = () => {
    const name = document.getElementById('name-field').value;
    const phone = document.getElementById('phone-field').value;
    const option = document.getElementById('option-field').value;
    const message = document.getElementById('message-field').value;

    const formData = {
      name: name,
      phone: phone,
      option: option,
      message: message,
    };

    fetch(`${API_URL}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': API_KEY, 
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setStatusMessage(t("contact-submit-success"));
          setStatusType('success'); // For success status
        } else {
          setStatusMessage(t("contact-submit-failure"));
          setStatusType('error'); // For error status
        }
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setStatusMessage(t("contact-submit-failure"));
        setStatusType('error');
      });
  };

  return (
    <>
      <PageHeader
        title={t("contact-page-title")}
        subtitle={t("contact-page-subtitle")}
      />
      <div className='container'>
        <section className='contact-page'>
          <article className='contact-info'>
            <div className='info-item'>
              <h3 className='item-title'>{t("contact-call-us")}</h3>
              <a href="tel:+38163366405" className='item-data' aria-label={t("contact-call-us")}>
                <div className='item-icon'>
                  <FaPhoneAlt />
                </div> 
                <h4>+381 63 366 405 (Daliborka)</h4>
              </a>
            </div>
            <div className='info-item'>
              <h3 className='item-title'>{t("contact-email-us")}</h3>
              <a href="mailto:agent@nekretnine-junona.rs" className='item-data' aria-label={t("contact-email-us")}>
                <div className='item-icon'>
                  <FaEnvelope />
                </div> 
                <h4>agent@nekretnine-junona.rs</h4>
              </a>
            </div>
            <div className='info-item'>
              <h3 className='item-title'>{t("contact-visit-us")}</h3>
              <a href="https://maps.app.goo.gl/Akybvwsbgm1TzmUB7" className='item-data' aria-label={t("contact-visit-us")}>
                <div className='item-icon'>
                  <FaLocationCrosshairs />
                </div> 
                <h4>IV Kraljevački bataljon 31/28a, <strong>Kraljevo</strong></h4>
              </a>
            </div>
            <div className='info-item'>
              <h3 className='item-title'>{t("contact-find-us")}</h3>
              <div className='map'>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d447.55251255456824!2d20.68657367396966!3d43.72177670114495!2m3!1f0!2f0.00005173270933312936!3f0!3m2!1i1024!2i768!4f35!3m3!1m2!1s0x4757015f66062d63%3A0x5347c31a51cda5a3!2sAgencija%20za%20nekretnine%20Junona!5e1!3m2!1ssr!2srs!4v1723232545050!5m2!1ssr!2srs"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title={t("contact-location")}
                ></iframe>
              </div>
            </div>
            <div className='info-item'>
              <h3 className='item-title'>{t("contact-follow-us")}</h3>
              <div className='item-data'>
                <a href="https://www.facebook.com/agencijajunona" className='item-icon' aria-label={t("contact-follow-us-facebook")}>
                  <FaFacebookF />
                </a> 
                <a href="https://www.instagram.com/nekretnine_junona/" className='item-icon' aria-label={t("contact-follow-us-instagramm")}>
                  <IoLogoInstagram />
                </a> 
              </div>
            </div>
          </article>
          <aside className='contact-us-form'>
            <div className="logo-container">
              <div className='logo-image'>
                <Logo theme={theme} type={'vertical'} alt={t("contact-logo-alt")} />
              </div>
            </div>
            <div className='form-container'>
              <h2>{t("contact-contact-us")}</h2>
              <form className='contact-form' aria-label={t("contact-contact-us-aria")}>
                <input id="name-field" type="text" placeholder={t('contact-your-name')} className='contact-input-field' required />
                <input id="phone-field" type="tel" placeholder={t('contact-phone-number')} className='contact-input-field' required />
                <select id="option-field" className='contact-select-field' required>
                  <option value="">{t('contact-choose-options')}</option>
                  <option value={t('contact-option-sale-interest')}>{t('contact-option-sale-interest')}</option>
                  <option value={t('contact-option-investment-company')}>{t('contact-option-investment-company')}</option>
                  <option value={t('contact-option-purchase-interest')}>{t('contact-option-purchase-interest')}</option>
                  <option value={t('contact-option-rental-interest')}>{t('contact-option-rental-interest')}</option>
                  <option value={t('contact-option-leasing-interest')}>{t('contact-option-leasing-interest')}</option>
                  <option value={t('contact-option-information-only')}>{t('contact-option-information-only')}</option>
                  <option value={t('contact-option-other')}>{t('contact-option-other')}</option>
                </select>
                <textarea id="message-field" placeholder={t('contact-input-message')} className='contact-text-area'></textarea>
                <button type="button" className='action-button' onClick={generateEmail}>{t("contact-submit-button")}</button>{statusMessage && (
                <div className={`status-message ${statusType}`}>
                    {statusMessage}
                  </div>
                )}
              </form>
            </div>
          </aside>
        </section>
      </div>
    </>
  );
};

export default ContactPage;
