// 1. React and Hooks
import React, { useState, useEffect } from 'react';

// 2. Third-Party Libraries
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// 3. Local Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import OfferPage from './pages/OfferPage/OfferPage';
import CollaborationPage from './pages/CollaborationPage/CollaborationPage';
import ItemPage from './pages/ItemPage/ItemPage';

// 4. Assets (Images, etc.)

// 5. Styles
import './App.css';

const App = () => {
  const { t, i18n } = useTranslation();

  // Detect initial theme preference
  const getInitialTheme = () => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return prefersDark ? 'dark' : 'light';
  };

  const [theme, setTheme] = useState(getInitialTheme());

  // Handle theme changes
  useEffect(() => {
    const root = document.documentElement;

    if (theme === 'dark') {
      root.style.setProperty('--text-color', 'var(--dark-text-color)');
      root.style.setProperty('--secondary-text-color', 'var(--dark-secondary-text-color)');
      root.style.setProperty('--background-color', 'var(--dark-background-color)');
      root.style.setProperty('--background-color-half-transparent', 'var(--dark-background-color-half-transparent)');
      root.style.setProperty('--secondary-background-color', 'var(--dark-secondary-background-color)');

      const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', 'var(--dark-background-color)');
      }
    } else {
      root.style.setProperty('--text-color', 'var(--light-text-color)');
      root.style.setProperty('--secondary-text-color', 'var(--light-secondary-text-color)');
      root.style.setProperty('--background-color', 'var(--light-background-color)');
      root.style.setProperty('--background-color-half-transparent', 'var(--light-background-color-half-transparent)');
      root.style.setProperty('--secondary-background-color', 'var(--light-secondary-background-color)');

      const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', 'var(--light-background-color)');
      }
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  // Handle language changes
  const pickLanguage = (language) => {
    if (language === 'sr') {
      i18n.changeLanguage('sr');
      document.documentElement.lang = language;
    } else if (language === 'en') {
      i18n.changeLanguage('en');
      document.documentElement.lang = language;
    } else if (language === 'ru') {
      i18n.changeLanguage('ru');
      document.documentElement.lang = language;
    }
  };

  return (
    <Router>
      <Header theme={theme} toggleTheme={toggleTheme} pickLanguage={pickLanguage}/>
      <Routes>
        <Route path="/" element={<HomePage theme={theme} />} />
        <Route path="/ponuda" element={<OfferPage />} />
        <Route path="/ponuda/item" element={<ItemPage />} />
        <Route path="/o-nama" element={<AboutPage />} />
        <Route path="/saradnja" element={<CollaborationPage />} />
        <Route path="/kontakt" element={<ContactPage theme={theme}/>} />
      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;
