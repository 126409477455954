import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
    }
  },
  sr: {
    translation: {
        // HEADER
        "header-aria-label": "Glavna navigacija",
        "header-home-link": "Vrati se na početnu stranicu",
        "header-navigation-aria": "Glavni navigacioni meni",
        
        "header-offer": "PONUDA",
        "header-selling": "Prodaja",
        "header-renting": "Izdavanje",
        "header-new-development": "Novogradnja",
        "header-dropdown-offer": "Padajući meni ponude nekretnina",
        
        "header-information": "INFORMACIJE",
        "header-blog": "Blog",
        "header-about-us": "O nama",
        "header-cooperation": "Saradnja",
        "header-dropdown-information": "Padajući meni informacija",
        
        "header-contact": "KONTAKT",
        
        "header-apartments-junona-link": "Link do Apartmani Junona platforme",
        "header-junona-apartments-alt": "Apartmani Junona logo",
        
        "header-language": "Srpski",
        "header-dropdown-language": "Padajući meni za izbor jezika",

        "header-switch-to-dark": "Prebaci se u tamni režim",
        "header-switch-to-light": "Prebaci se u svetli režim",
        
        "header-open-menu": "Otvori bočni meni",
        "header-sidebar": "Bočni navigacioni meni",

      
        // FOOTER
        "footer-having-questions": "Da li imate pitanja za nas?",
        "footer-contact-us": "Kontaktirajte nas",
        "footer-useful-links": "Korisni linkovi",

        "footer-useful-link-kopaonik-new": "Novogradnja apartmani Kopaonik",
        "footer-useful-link-kopaonik-sale": "Prodaja starogradnje Kopaonik",
        "footer-useful-link-kraljevo-houses": "Prodaja kuća Kraljevo",
        "footer-useful-link-kraljevo-apartments": "Prodaja stanova Kraljevo",
        "footer-useful-link-belgrade-apartments": "Prodaja stanova Beograd",

        "footer-kopaonik": "Planirate odmor na Kopaoniku?",
        "footer-checkout-apartments": "Posetite našu platformu za izdavanje apartmana",
        "footer-apartments-junona": "APARTMANI JUNONA",
        "footer-all-rights-reserved": "Sva prava zadržana",


        // HERO
        "hero-background-alt": "Ilustracija grada u Nekretnine Junona svetu",
        "hero-background-portrait-alt": "Ilustracija grada u Nekretnine Junona svetu",
        "hero-billboard-alt": "Bilbord Nekretnine Junona",
        "hero-billboard-portrait-alt": "Bilbord Nekretnine Junona",
        "hero-lights-alt": "Svetla na bilbordu Nekretnine Junona",
        "hero-lights-portrait-alt": "Svetla na bilbordu Nekretnine Junona",
        "hero-title-line-1": "Ne čekaj da kupiš nekretninu.",
        "hero-title-line-2": "Kupi nekretninu i čekaj.",
        "hero-button": "POGLEDAJTE PONUDU",
        "hero-subtext": "(kupite nekretninu bez provizije)",


        // HOMEPAGE
        "homepage-document-title": "Početna | Nekretnine Junona",
        "homepage-meet-us-title": "Agencija koja razume vaše potrebe",
        "homepage-meet-us-subtitle": "Kod nas su klijenti uvek na prvom mestu",
        "homepage-meet-us-description": "Mi uvek težimo da pružimo najbolje usluge klijentima, sa fokusom na transparentnost i poverenje. Naša agencija je tu da vas vodi kroz svaki korak, bilo da kupujete ili prodajete nekretninu.",
        "homepage-about-us-button": "Upoznajte nas",

        "homepage-property-image-1-alt": "Kuća u Kraljevu",
        "homepage-property-image-2-alt": "Moderan apartman na Kopaoniku u objektu Goma u Vikend naselju",
        "homepage-property-image-3-alt": "Moderan apartman u Konacima na Kopaoniku",
        "homepage-property-image-4-alt": "Kafe bar na Kopaoniku",
        "homepage-property-image-5-alt": "Apartman u objektu Gencian Resort na Kopaoniku u naselju Treska",

        "homepage-sell-property-title": "Prodaja nekretnina nikad nije bila lakša",
        "homepage-sell-property-subtitle": "Poverite nam prodaju ili izdavanje svoje nekretnine",
        "homepage-sell-property-description": "Bilo da prodajete stan, kuću ili zemljište, bićemo tu da vas vodimo kroz proces do najbolje ponude na tržištu. Pružamo kompletne usluge, od procene vrednosti do marketinga i vođenja pregovora.",
        "homepage-cooperation-button": "Saznajte više",

        "homepage-sell-property-benefit-1": "✓ Oglašavanje nekretnina",
        "homepage-sell-property-benefit-2": "✓ Procena nekretnine i savetovanje",
        "homepage-sell-property-benefit-3": "✓ Pregovaranje sa kupcima",
        "homepage-sell-property-benefit-4": "✓ Velika vidljivost na internetu",
        "homepage-sell-property-benefit-5": "✓ Pravna podrška",

        "homepage-new-developments-title": "Najveća ponuda apartmana na Kopaoniku",
        "homepage-new-developments-subtitle": "Upoznajte sa nama sve čari najpoznatije planine u Srbiji",
        "homepage-new-developments-description": "Naša ekskluzivna ponuda luksuznih apartmana na Kopaoniku savršena je za ljubitelje prirode i skijanja. Pružamo vrhunske lokacije i sadržaje, sa mogućnostima ulaganja u nekretnine rastuće vrednosti.",
        "homepage-look-at-offer-button": "Pogledajte ponudu",

        "homepage-kopaonik-image-1-alt": "Luksuzni apartman na Kopaoniku noću",
        "homepage-kopaonik-image-2-alt": "Planinska vila u Kopaoniku okružena snegom",
        "homepage-kopaonik-image-3-alt": "Apartman u prirodnom okruženju na Kopaoniku",


        // CONTACT
        "contact-document-title": "Kontakt | Nekretnine Junona",
        "contact-page-title": "Hajde da pričamo!",
        "contact-page-subtitle": "Kontaktirajte nas, želimo da vas upoznamo!",
        "contact-call-us": "Pozovite nas",
        "contact-email-us": "Pošaljite nam email",
        "contact-visit-us": "Posetite nas",
        "contact-find-us": "Pronadjite nas na mapi",
        "contact-location": "Lokacija kancelarije agencije za nekretnine Junona",
        "contact-follow-us": "Zapratite nas",
        "contact-follow-us-facebook": "Zapratite nas na Fejsbuku",
        "contact-follow-us-instagram": "Zapratite nas na Instagramu",

        "contact-logo-alt": "Nekretnine Junona logo",
        "contact-contact-us": "Javite nam se",
        "contact-contact-us-aria": "Forma za slanje upita",
        "contact-your-name": "Vaše ime",
        "contact-phone-number": "Broj telefona",
        "contact-choose-options": "Izaberite opciju",
        "contact-option-sale-interest": "Zanima me prodaja nekretnine",
        "contact-option-investment-company": "Predstavljam investitorsku kompaniju",
        "contact-option-purchase-interest": "Zanima me kupovina nekretnine",
        "contact-option-rental-interest": "Zanima me izdavanje nekretnine",
        "contact-option-leasing-interest": "Zanima me iznajmljivanje nekretnine",
        "contact-option-information-only": "Samo želim da se informišem",
        "contact-option-other": "Ostalo",
        "contact-input-message": "Unesite poruku",
        "contact-submit-button": "POŠALJI",
        "contact-submit-success": "Uspešno ste poslali upit!",
        "contact-submit-failure": "Došlo je do greške prilikom slanja upita!",


        // COLLABORATION
        "collaboration-document-title": "Saradnja | Nekretnine Junona",
        "collaboration-page-title": "Hajde da saradjujemo!",
        "collaboration-page-subtitle": "Izaberite opciju koja vas najbolje opisuje i saznajte kako bi naša saradnja izgledala!",
        "collaboration-choose-option": "Izaberite opciju koja vas najbolje opisuje",
        "collaboration-seller-button": "Želim da <strong>prodam</strong> nekretninu koju posedujem",
        "collaboration-company-button": "Predstavljam <strong>investitorsku firmu</strong>",
        "collaboration-issuer-button": "Želim da <strong>izdam</strong> svoju nekretninu na duži vremeski period",
        "collaboration-buyer-button": "Želim da <strong>kupim</strong> nekretninu",
        "collaboration-renter-button": "Želim da <strong>iznajmim</strong> nekretninu",

        "collaboration-seller-title": "Želite da prodate vašu nekretninu?",
        "collaboration-seller-description": "Došli ste na pravo mesto. Za samo <strong>2%</strong> kupoprodajne cene nekretnine prilikom prometa dobijate potpunu podršku na svakom koraku.",
        "collaboration-seller-benefits": [
          "Fotografisanje nekretnina",
          "Izrada reklamnog materijala",
          "Oglašavanje nekretnine",
          "Velika vidljivost na internetu",
          "Procena nekretnine i savetovanje",
          "Pregovaranje sa kupcima",
          "Prezentacija nekretnine kupcima",
          "Izrada predugovora ili ugovora",
          "Priprema i pomoć prilikom overe"
        ],
        "collaboration-company-title": "Predstavljate investitorsku firmu?",
        "collaboration-company-description": "Gradite i razmišljate o podršci agencije u prodaji. Naša provizija je <strong>2%</strong> i za vas smo pripremili dva modela saradnje.",
        "collaboration-company-model1-title": "Model 1: Podrška u prodaji",
        "collaboration-company-model1-description": "Podelićete vašu ponudu sa nama, vaša ponuda stanova će postati deo naše ponude i biti dostupna našim klijentima. Dobijate kompletnu podršku u prodaji vaših stambenih jedinica našim klijentima.",
        "collaboration-company-model1-benefits": [
          "Pregovaranje sa kupcima",
          "Prezentacija ponude",
          "Marketing i oglašavanje"
        ],
        "collaboration-company-model2-title": "Model 2: Prodajni agent",
        "collaboration-company-model2-description": "Želite da se bavite gradnjom, ali ne i prodajom? Prepustite prodaju nama. Ukoliko ste spremni da nam ustupite mesto ekskluzivne (jedine) agencije koja će vas zastupati, za isti iznos provizije, dobijate potpuno gratis sledeće benefite:",
        "collaboration-company-model2-benefits": [
          "Izrada website-a",
          "Marketing i oglašavanje",
          "Vodjenje društvenih mreža",
          "Snimanje reklamnog materijala",
          "Velika vidljivost na internetu",
          "Pregovaranje sa kupcima",
          "Prezentacija ponude"
        ],
        "collaboration-issuer-title": "Želite da izdate vašu nekretninu na duži period?",
        "collaboration-issuer-description": "Došli ste na pravo mesto. Za samo <strong>50%</strong> iznosa prve rente dobijate potpunu podršku na svakom koraku.",
        "collaboration-issuer-benefits": [
          "Fotografisanje nekretnina",
          "Izrada reklamnog materijala",
          "Oglašavanje nekretnine",
          "Velika vidljivost na internetu",
          "Procena nekretnine i savetovanje",
          "Pregovaranje sa iznajmljivačima",
          "Prezentacija nekretnine iznajmljivačima",
          "Izrada ugovora"
        ],
        "collaboration-buyer-title": "Želite da kupite nekretninu?",
        "collaboration-buyer-description": "Za vas smo pripremili dva modela saradnje.",
        "collaboration-buyer-model1-title": "Model 1: Regularna ponuda",
        "collaboration-buyer-model1-description": "Pogledajte našu ponudu i kontaktirajte nas. Naša agencija ne naplaćuje proviziju kupcima nekretnina iz naše ponude.",
        "collaboration-buyer-model2-title": "Model 2: Premium kupac",
        "collaboration-buyer-model2-description": "Želite da kupite nekretninu što pre? Želite prioritetni pristup ponudi? Za samo <strong>2%</strong> provizije prilikom prometa nekretnine naš agent će postati vaš lični agent i pružiće vam kompletnu podršku prilikom kupovine. U okviru ovog modela dobijate:",
        "collaboration-buyer-model2-benefits": [
          "Pristup široj ponudi",
          "Prioritetni pristup ponudi",
          "Aktivno traženje nekretnine",
          "Pregovaranje sa prodavcem",
          "Potpuna podrška i savetovanje"
        ],
        "collaboration-renter-title": "Želite da iznajmite nekretninu?",
        "collaboration-renter-description": "Pogledajte ponudu stanova za izdavanje na našem sajtu. Naša agencija ne naplaćuje proviziju iznajmljivaču prilikom iznajmljivanja nekretnine.",

        "collaboration-action-button-contact": "Kontaktirajte nas!",
        "collaboration-action-button-offer": "Pogledajte ponudu",
        

        // ABOUT US
        "about-us-document-title": "O nama | Nekretnine Junona",
        "about-us-page-title": "Upoznajte nas",
        "about-us-page-subtitle": "Naša priča, vizija i usluge",
        "about-us-section-aria": "Sekcija o nama",
        "about-us-our-story-alt": "Osnivači agencije za nekretnine, majka i sin, gledaju u gradjevinski projekat",
        "about-us-our-story-title": "Naša priča",
        "about-us-our-story-text-1": "Naše putovanje u svet nekretnina započelo je na Kopaoniku, gde smo, u saradnji sa jednom od gradjevinskih kompanija, prepoznali da tržištu nedostaje pouzdan partner koji stavlja klijente na prvo mesto. Uvideli smo da mnogim agencijama nedostaje lični pristup i posvećenost, zbog čega smo odlučili da našu uslugu oblikujemo tako da se fokusiramo na dugoročne odnose zasnovane na poverenju, iskrenosti i odgovornosti.",
        "about-us-our-story-text-2": "Daliborka Tonić, osnivač i direktor, ima višegodišnje iskustvo u upravljanju i finansijama. Njena snaga leži u iskrenom i otvorenom pristupu, kao i u sposobnosti da prepozna i odgovori na potrebe svakog klijenta.",
        "about-us-our-story-text-3": "Danilo Tonić, sa iskustvom u oblasti veštačke inteligencije, koristi napredne metode prikupljanja, analize i modeliranja podataka kako bi našim klijentima pružio tačne informacije o tržištu. Takodje, on kontinuirano optimizuje pozicioniranje naših nekretnina na internetu, osiguravajući njihovu maksimalnu vidljivost i konkurentnost.",
        "about-us-our-story-text-4": "Majka i sin zajedno grade porodični biznis s ciljem da klijentima pruže najbolje moguće iskustvo. Naš pristup kombinuje razumevanje klijenata i korišćenje naprednih sistema podrške, kako bismo im omogućili da uvek ostvare najbolju moguću ponudu.",

        "about-us-our-vision-title": "Naša vizija",
        "about-us-our-vision-text": "Naš cilj je da budemo partneri na koje se klijenti mogu osloniti u svakom trenutku. Verujemo da uspeh dolazi iz odnosa, a ne samo iz brojeva. Želimo da svaki klijent oseća sigurnost i poverenje, bilo da kupuje ili investira.",
        "about-us-empathy-title": "EMPATIJA.",
        "about-us-empathy-text": "Svaki klijent ima jedinstvene potrebe, a mi ih poštujemo i razumemo. Naš cilj je da budemo više od posrednika – da pružimo podršku u donošenju važnih životnih odluka.",
        "about-us-professionalism-title": "PROFESIONALIZAM.",
        "about-us-professionalism-text": "Vodimo se odgovornošću i posvećenosti detaljima, pružajući svakom klijentu najviši nivo usluge.",
        "about-us-trust-title": "POVERENJE.",
        "about-us-trust-text": "Poverenje je temelj naše saradnje. Iskrenost i transparentnost su ključne vrednosti koje gradimo sa svakim klijentom.",
        
        "about-us-our-services-title": "Naše usluge",
        "about-us-service-1-title": "Proverena ponuda",
        "about-us-service-1-text": "Pružamo vam pristup pažljivo odabranim nekretninama koje su prošle kroz detaljnu proveru. Naš cilj je da omogućimo sigurne investicije i pouzdane izbore, bilo da kupujete za život ili ulaganje.",
        "about-us-service-2-title": "Personalizovana podrška",
        "about-us-service-2-text": "Svaki klijent je jedinstven, zato prilagodjavamo naš pristup vašim potrebama. Bilo da prvi put kupujete nekretninu ili ste iskusan investitor, pružamo podršku i savete kroz svaki korak – od prvog pregleda do zaključivanja kupoprodaje.",
        "about-us-service-3-title": "Transparentnost i poverenje",
        "about-us-service-3-text": "Poverenje gradimo kroz transparentnost. Koristimo napredne sisteme za praćenje tržišta i delimo precizne i proverene informacije o nekretninama, bez skrivenih troškova i neprijatnih iznenadjenja.",
        
        "about-us-why-us-title": "Zašto baš mi?",
        "about-us-why-us-main-reason": "Zato što vam je potreban pouzdan i iskren partner koji stavlja vaše interese na prvo mesto i završava posao za vas",
        "about-us-why-us-sub-reason": "Ne gubite vreme – kontaktirajte nas i započnite svoju sledeću uspešnu investiciju s nama",
        "about-us-contact-us": "Kontaktirajte nas",


        // BLOG
        "blog-home-page-title": "Blog",
        "blog-home-page-subtitle": "Pročitajte više o raznim temama i uplovite sa nama u svet nekretnina!",


        // OFFER PAGE
        "offer-page-document-title": "Ponuda | Nekretnine Junona",
        "offer-page-show-filters": "Prikaži filtere",
        "offer-page-filter-remove": "Poništi",

        // ITEM PAGE
        "item-page-failed-to-fetch": "Došlo je do greške, tražena nekretnina verovatno ne postoji u bazi!",
        "item-page-loading": "Učitavanje",
        "item-page-success": "Uspešno ste poslali upit!",
        "item-page-failure": "Došlo je do greške",
        "item-page-about-estate": "O nekretnini",
        "item-page-key-features": "Ključne karakteristike",
        "item-page-features": "Osobine",
        "item-page-location": "Lokacija",
        "item-page-conclusion": "Zaključak",
        "item-page-is-this-for-you": "Da li je ova nekretnina za vas?",
        "item-page-provision": "provizije",
        "item-page-contact-us": "Kontaktirajte nas",
        "item-page-or": "ili",
        "item-page-report-interest": "Prijavite interesovanje",
        "item-page-name": "Ime",
        "item-page-phone": "Broj telefona",
      }
  },
  ru: {
    translation: {
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'sr', 
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
