import React from 'react';
import { BiSolidArea } from "react-icons/bi";
import { FaRulerCombined } from "react-icons/fa6";
import { SiLevelsdotfyi } from "react-icons/si";
import { MdBedroomChild } from "react-icons/md";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { FaFileContract } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaParking } from "react-icons/fa";
import { FaRegCompass } from "react-icons/fa";
import { GrElevator } from "react-icons/gr";
import { MdOutlineBalcony } from "react-icons/md";
import { BiSolidCarGarage } from "react-icons/bi";
import { FaRoad } from "react-icons/fa6";
import { AiFillThunderbolt } from "react-icons/ai";
import { IoMdWater } from "react-icons/io";
import { FaNetworkWired } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { GiCellarBarrels } from "react-icons/gi";
import { BsCalendar2DateFill } from "react-icons/bs";
import { IoFootsteps } from "react-icons/io5";
import { GiHeatHaze } from "react-icons/gi";
import { GiWindow } from "react-icons/gi";
import { GiBrickWall } from "react-icons/gi";

const FeatureTile = ({ featureKey, featureValue }) => {
  let icon = null;
  let formattedValue = featureValue;

  // Map keys to corresponding icons and format values
  switch (featureKey) {
    case 'area':
      icon = <FaRulerCombined />;
      formattedValue = `${featureValue} m²`;
      break;
    case 'land_area':
      icon = <BiSolidArea />;
      formattedValue = `${featureValue} a`;
      break;
    case 'number_of_floors':
      icon = <SiLevelsdotfyi />;
      formattedValue = `${featureValue} sprat/a`;
      break;
    case 'number_of_rooms':
      icon = <MdBedroomChild />;
      formattedValue = featureValue;
      break;
    case 'floor_type':
      icon = <SiLevelsdotfyi />;
      formattedValue = featureValue;
      break;
    case 'garage':
      icon = <BiSolidCarGarage />;
      formattedValue = featureValue ? 'Ima garažu' : 'Nema garažu';
      break;
    case 'parking_garage':
    case 'parking':
      icon = <FaParking />;
      formattedValue = featureValue ? 'Ima parking' : 'Nema parking';
      break;
    case 'elevator':
      icon = <GrElevator />;
      formattedValue = featureValue ? 'Ima lift' : 'Nema lift';
      break;
    case 'basement':
      icon = <GiCellarBarrels />;
      formattedValue = featureValue ? 'Ima podrum' : 'Nema podrum';
      break;
    case 'terrace':
      icon = <MdOutlineBalcony />;
      formattedValue = featureValue ? 'Ima terasu' : 'Nema terasu';
      break;
    case 'paved_road':
      icon = <FaRoad />;
      formattedValue = featureValue ? 'Asfaltiran put' : 'Neasfaltiran put';
      break;
    case 'electricity':
      icon = <AiFillThunderbolt />;
      formattedValue = featureValue ? 'Priključena struja' : 'Nepriključena struja';
      break;
    case 'sewage':
      icon = <IoMdWater />;
      formattedValue = featureValue ? 'Ima kanalizaciju' : 'Nema kanalizaciju';
      break;
    case 'telephone':
      icon = <FaNetworkWired />;
      formattedValue = featureValue ? 'Priključen telefon' : 'Nepriključen telefon';
      break;
    case 'orientation':
      icon = <FaRegCompass />;
      formattedValue = featureValue;
      break;
    case 'registration_status':
      icon = <FaFileContract />;
      formattedValue = featureValue;
      break;
    case 'address':
      icon = <FaLocationDot />;
      formattedValue = `${featureValue.settlement}, ${featureValue.city}`;
      break;
    case 'number_of_buildings':
      icon = <BsBuildingsFill />;
      formattedValue = `Broj lamela: ${featureValue}`;
      break;
    case 'estimated_finish_date':
      icon = <BsCalendar2DateFill />;
      formattedValue = `Rok gradnje: ${featureValue}`;
      break;
    case 'flooring':
      icon = <IoFootsteps />;
      formattedValue = `${featureValue}`;
      break;
    case 'joinery':
      icon = <GiWindow />;
      formattedValue = `${featureValue}`;
      break;
    case 'material':
      icon = <GiBrickWall />;
      formattedValue = ` ${featureValue}`;
      break;
    case 'heating':
      icon = <GiHeatHaze />;
      formattedValue = `Grejanje: ${featureValue}`;
      break;
    default:
      icon = <BsFillInfoSquareFill />;
      formattedValue = featureValue || 'N/A';
      break;
  }

  return (
    <div className="feature-tile">
      {icon} <span>{formattedValue}</span>
    </div>
  );
};

export default FeatureTile;
