import React from 'react';

import './Logo.css';

import LogoInlineLight from '../../assets/logo/light/logo_horizontal.svg';
import LogoInlineDark from '../../assets/logo/dark/logo_horizontal.svg';
import LogoVerticalLight from '../../assets/logo/light/logo_vertical.svg';
import LogoVerticalDark from '../../assets/logo/dark/logo_vertical.svg';
import LogoApartmentsLight from '../../assets/logo/light/logo_apartments.svg';
import LogoApartmentsDark from '../../assets/logo/dark/logo_apartments.svg';

const Logo = ({ theme, type, forceLight, forceDark, altText = "Company Logo" }) => {
  let logoSrc;
  let logoClass;

  if (type === 'vertical') {
    logoClass = 'logo-vertical';
    if (forceLight) {
      logoSrc = LogoVerticalLight;
    } else if (forceDark) {
      logoSrc = LogoVerticalDark;
    } else {
      logoSrc = theme === 'light' ? LogoVerticalLight : LogoVerticalDark;
    }
  } else if (type === 'apartments') {
    logoClass = 'logo-apartments-junona';
    if (forceLight) {
      logoSrc = LogoApartmentsLight;
    } else if (forceDark) {
      logoSrc = LogoApartmentsDark;
    } else {
      logoSrc = theme === 'light' ? LogoApartmentsLight : LogoApartmentsDark;
    }
  } else {
    logoClass = 'logo-inline';
    if (forceLight) {
      logoSrc = LogoInlineLight;
    } else if (forceDark) {
      logoSrc = LogoInlineDark;
    } else {
      logoSrc = theme === 'light' ? LogoInlineLight : LogoInlineDark;
    }
  }

  return <img src={logoSrc} className={logoClass} alt={altText} />;
};

export default Logo;
