// 1. React and Hooks
import React from 'react';

// 2. Third-Party Libraries
import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

// 3. Local Components
import Logo from '../Logo/Logo';

// 4. Assets (Images, etc.)

// 5. Styles
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer" role="contentinfo">
      <div className="footer-content">
        <div className="footer-section footer-left">
          <div className="footer-logo">
            <Logo theme={'light'} type={'inline'} forceDark={true} alt="Nekretnine Junona Logo" />
          </div>
          <div className="footer-socials" aria-label="Social Media Links">
            <a href="https://www.instagram.com/nekretnine_junona/"
              className="footer-social-link"
              aria-label="Follow us on Instagram"
              rel="noopener noreferrer"
              target="_blank">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/agencijajunona"
              className="footer-social-link"
              aria-label="Follow us on Facebook"
              rel="noopener noreferrer"
              target="_blank">
              <FaFacebookF />
            </a>
          </div>
          <div className="footer-questions">
            <h3>{t("footer-having-questions")}</h3>
            <Link to="/kontakt" className='action-button light-colors'>
              {t("footer-contact-us")}
            </Link>
          </div>
        </div>

        <div className="footer-section footer-middle">
          <div className="footer-column">
            <h3>{t("footer-useful-links")}</h3>
            <ul>
              <li><Link to="/ponuda?model=NOVOGRADNJA&city=Kopaonik">{t("footer-useful-link-kopaonik-new")}</Link></li>
              <li><Link to="/ponuda?model=PRODAJA&city=Kopaonik">{t("footer-useful-link-kopaonik-sale")}</Link></li>
              <li><Link to="/ponuda?model=PRODAJA&city=Kraljevo&type=KUĆA">{t("footer-useful-link-kraljevo-houses")}</Link></li>
              <li><Link to="/ponuda?model=PRODAJA&city=Kraljevo&type=STAN">{t("footer-useful-link-kraljevo-apartments")}</Link></li>
              <li><Link to="/ponuda?model=PRODAJA&city=Beograd&type=STAN">{t("footer-useful-link-belgrade-apartments")}</Link></li>
            </ul>
          </div>
        </div>

        <div className="footer-section footer-right">
          <div className="footer-column">
            <h3>{t("footer-kopaonik")}</h3>
            <h4>{t("footer-checkout-apartments")}</h4>
            <a className="footer-link" href="https://apartmani-junona.rs" target="_blank" rel="noopener noreferrer" aria-label={t("header-apartments-junona-link")}>
              <Logo theme={'light'} type={'apartments'} forceDark={true} alt="Apartmani Junona logo" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-copyright">
        <hr className="footer-line" />
        <p>&copy; 2024 Nekretnine Junona. {t("footer-all-rights-reserved")}</p>
      </div>
    </footer>
  );
};

export default Footer;
