// 1. React and Hooks
import React, {useEffect} from 'react';

// 2. Third-Party Libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// 3. Local Components
import Hero from '../../components/Hero/Hero';

// 4. Assets (Images, etc.)
import PropertyImage1 from '../../assets/images/property_image1.jpg';
import PropertyImage2 from '../../assets/images/property_image2.jpg';
import PropertyImage3 from '../../assets/images/property_image3.jpg';
import PropertyImage4 from '../../assets/images/property_image4.jpg';
import PropertyImage5 from '../../assets/images/property_image5.jpg';

import NewDevelopmentImage1 from '../../assets/images/kopaonik_image1.jpg';
import NewDevelopmentImage2 from '../../assets/images/kopaonik_image2.jpg';
import NewDevelopmentImage3 from '../../assets/images/kopaonik_image3.jpg';

// 5. Styles
import './HomePage.css';

const HomePage = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("homepage-document-title");
  }, []);
  
  const { t } = useTranslation();

  return (
    <>
      <Hero theme={theme} />
      <div className="container">
        <section className="home-two-column-container">
          <div className="home-left-column-container">
            <h1>{t('homepage-meet-us-title')}</h1>
            <h3>{t('homepage-meet-us-subtitle')}</h3>
            <p>{t('homepage-meet-us-description')}</p>
            <Link to="/o-nama" className="action-button">{t('homepage-about-us-button')}</Link>
          </div>

          <div className="property-images">
            <div className="left-property-images">
              <img src={PropertyImage1} alt={t('homepage-property-image-1-alt')} className="left-property-image" />
              <img src={PropertyImage2} alt={t('homepage-property-image-2-alt')} className="left-property-image" />
              <img src={PropertyImage3} alt={t('homepage-property-image-3-alt')} className="left-property-image" />
            </div>
            <div className="right-property-images">
              <img src={PropertyImage4} alt={t('homepage-property-image-4-alt')} className="right-property-image" />
              <img src={PropertyImage5} alt={t('homepage-property-image-5-alt')} className="right-property-image" />
            </div>
          </div>
        </section>

        <section className="home-two-column-container">
          <div className="home-left-column-container">
            <h1>{t('homepage-sell-property-title')}</h1>
            <h3>{t('homepage-sell-property-subtitle')}</h3>
            <p>{t('homepage-sell-property-description')}</p>
            <Link to="/saradnja" className="action-button">{t('homepage-cooperation-button')}</Link>
          </div>
          <div className="services-bullets">
            <ul>
              <li>{t('homepage-sell-property-benefit-1')}</li>
              <li>{t('homepage-sell-property-benefit-2')}</li>
              <li>{t('homepage-sell-property-benefit-3')}</li>
              <li>{t('homepage-sell-property-benefit-4')}</li>
              <li>{t('homepage-sell-property-benefit-5')}</li>
            </ul>
          </div>
        </section>

        <section className="home-two-column-container">
            <div className="home-left-column-container">
              <h1>{t('homepage-new-developments-title')}</h1>
              <h3>{t('homepage-new-developments-subtitle')}</h3>
              <p>{t('homepage-new-developments-description')}</p>
              <Link to="/ponuda?model=NOVOGRADNJA&city=Kopaonik" className="action-button">{t('homepage-look-at-offer-button')}</Link>
            </div>
            <div className="new-developments-images">
              <img src={NewDevelopmentImage1} alt={t('homepage-kopaonik-image-1-alt')} className="kopaonik-image" />
              <img src={NewDevelopmentImage2} alt={t('homepage-kopaonik-image-2-alt')} className="kopaonik-image" />
              <img src={NewDevelopmentImage3} alt={t('homepage-kopaonik-image-3-alt')} className="kopaonik-image" />
            </div>
        </section>
      </div>
    </>
  );
};

export default HomePage;
