import React from 'react';
import './OfferCard.css';
import FeatureTile from '../FeatureTile/FeatureTile'; // Import the new component
import placeholderUrl from "../../assets/placeholder.png";

const OfferCard = ({ imageUrl, model, title, price, settlement, city, special_features }) => {

  // Helper function to render special features dynamically using the new component
  const renderSpecialFeatures = (features) => {
    return Object.keys(features).map((key, index) => (
      <FeatureTile key={index} featureKey={key} featureValue={features[key]} />
    ));
  };
  console.log(imageUrl)
  return (
    <div className="offer-card inverse-colors">
      <div className="offer-card-image">
        <img
          src={imageUrl}
          alt={title}
          onError={(e) => {
            e.target.src = placeholderUrl;
          }}
        />
      </div>
      <div className="offer-card-details">
        <h3>{title}</h3>
        <div className="price-container">
          <h1>{price}</h1>
          {model === 'IZDAVANJE' ? '/mesec' : ''}
        </div>
        <h3>{settlement}, {city}</h3>

        <div className="offer-card-icons">
          {special_features && renderSpecialFeatures(special_features)}
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
