// 1. React and Hooks
import React, { useEffect } from 'react';

// 2. Third-Party Libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// 3. Local Components
import PageHeader from '../../components/PageHeader/PageHeader';

// 4. Assets (Images, etc.)

// 5. Styles
import './CollaborationPage.css';


const CollaborationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("collaboration-document-title");
  }, []);
  
  const { t } = useTranslation();

  const scrollToDiv = (divId) => {
    const element = document.getElementById(divId);
    if (element) {
      const yOffset = -50;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      <PageHeader
        title={t("collaboration-page-title")}
        subtitle={t("collaboration-page-subtitle")}
      />
      <div className='container'> 
        <section className='collaboration-options'>
          <h2>{t('collaboration-choose-option')}</h2>
          <div className='options-list'>
            <button className='option-button' onClick={() => scrollToDiv('section-seller')} dangerouslySetInnerHTML={{__html: t("collaboration-seller-button")}} />
            <button className='option-button' onClick={() => scrollToDiv('section-company')} dangerouslySetInnerHTML={{__html: t("collaboration-company-button")}} />
            <button className='option-button' onClick={() => scrollToDiv('section-issuer')} dangerouslySetInnerHTML={{__html: t("collaboration-issuer-button")}} />
            <button className='option-button' onClick={() => scrollToDiv('section-buyer')} dangerouslySetInnerHTML={{__html: t("collaboration-buyer-button")}} />
            <button className='option-button' onClick={() => scrollToDiv('section-renter')} dangerouslySetInnerHTML={{__html: t("collaboration-renter-button")}} />
          </div>
        </section>
      </div>
      <div className='container'>
        <section className='collaboration-option-explained' id='section-seller'>
          <h2 dangerouslySetInnerHTML={{__html: t("collaboration-seller-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-seller-description")}} />
          <div className='collaboration-benefits'>
            <ul>
              {(Array.isArray(t('collaboration-seller-benefits', { returnObjects: true })) ? t('collaboration-seller-benefits', { returnObjects: true }) : []).map((benefit, index) => (
                <li key={index}>✓ {benefit}</li>
              ))}
            </ul>
          </div>
          <Link to="/kontakt" className='action-button inverse-colors'>{t('collaboration-action-button-contact')}</Link>
        </section>
        
        <section className='collaboration-option-explained' id='section-company'>
          <h2 dangerouslySetInnerHTML={{__html: t("collaboration-company-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-company-description")}} />
          <h3 dangerouslySetInnerHTML={{__html: t("collaboration-company-model1-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-company-model1-description")}} />
          <div className='collaboration-benefits'>
            <ul>
              {(Array.isArray(t('collaboration-company-model1-benefits', { returnObjects: true })) ? t('collaboration-company-model1-benefits', { returnObjects: true }) : []).map((benefit, index) => (
                <li key={index}>✓ {benefit}</li>
              ))}
            </ul>
          </div>
          <h3 dangerouslySetInnerHTML={{__html: t("collaboration-company-model2-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-company-model2-description")}} />
          <div className='collaboration-benefits'>
            <ul>
              {(Array.isArray(t('collaboration-company-model2-benefits', { returnObjects: true })) ? t('collaboration-company-model2-benefits', { returnObjects: true }) : []).map((benefit, index) => (
                <li key={index}>✓ {benefit}</li>
              ))}
            </ul>
          </div>
          <Link to="/kontakt" className='action-button inverse-colors'>{t('collaboration-action-button-contact')}</Link>
        </section>
        
        <section className='collaboration-option-explained' id='section-issuer'>
          <h2 dangerouslySetInnerHTML={{__html: t("collaboration-issuer-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-issuer-description")}} />
          <div className='collaboration-benefits'>
            <ul>
              {(Array.isArray(t('collaboration-issuer-benefits', { returnObjects: true })) ? t('collaboration-issuer-benefits', { returnObjects: true }) : []).map((benefit, index) => (
                <li key={index}>✓ {benefit}</li>
              ))}
            </ul>
          </div>
          <Link to="/kontakt" className='action-button inverse-colors'>{t('collaboration-action-button-contact')}</Link>
        </section>
        
        <section className='collaboration-option-explained' id='section-buyer'>
          <h2 dangerouslySetInnerHTML={{__html: t("collaboration-buyer-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-buyer-description")}} />
          <h3 dangerouslySetInnerHTML={{__html: t("collaboration-buyer-model1-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-buyer-model1-description")}} />
          <Link to="/ponuda" className='action-button inverse-colors'>{t('collaboration-action-button-offer')}</Link>
          
          <h3 dangerouslySetInnerHTML={{__html: t("collaboration-buyer-model2-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-buyer-model2-description")}} />
          <div className='collaboration-benefits'>
            <ul>
              {(Array.isArray(t('collaboration-buyer-model2-benefits', { returnObjects: true })) ? t('collaboration-buyer-model2-benefits', { returnObjects: true }) : []).map((benefit, index) => (
                <li key={index}>✓ {benefit}</li>
              ))}
            </ul>
          </div>
          <Link to="/kontakt" className='action-button inverse-colors'>{t('collaboration-action-button-contact')}</Link>
        </section>
        
        <section className='collaboration-option-explained' id='section-renter'>
          <h2 dangerouslySetInnerHTML={{__html: t("collaboration-renter-title")}} />
          <p dangerouslySetInnerHTML={{__html: t("collaboration-renter-description")}} />
          <Link to="/ponuda?model=IZDAVANJE" className='action-button inverse-colors'>{t('collaboration-action-button-offer')}</Link>
        </section>
      </div>
    </>
  );
};

export default CollaborationPage;
